import { IconButton } from '@mui/material';
import styled from '@emotion/styled';

export const StyledIconButton = styled(IconButton)`
  margin: 4px;
  background-color: #6501A3;
  color: #fff;
  &:hover {
    text-decoration: none;
    background-color: rgb(70, 0, 114);
  }
`;
