import { useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import SimpleError from '../../../../components/errors/simple-error/SimpleError';
import getPublicationId from '../../../../utils/getPublicationId';
import useMyPublication from '../../useMyPublication';
import StepHorariosClass from './class/StepHorariosClass';
import StepHorariosCourse from './course/StepHorariosCourse';
import StepHorariosFlexible from './flexible/StepHorariosFlexible';
import usePersonal from '../../../my-account/personal/usePersonal';

const StepHorarios = () => {
  const { identifier } = useParams();
  const location = useLocation();
  const id = getPublicationId(identifier as string);
  const { fetchAuthPublication, status: statusFetch, error: errorFetch, data: publication } = useMyPublication();
  const { 
    fetchAuthHasLastTermAccepted,
    saveAuthUserTermAccepted,
    hasLastTermAccepted,
  } = usePersonal();

  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);
  
  useEffect(() => {
    if (id && statusFetch === 'idle') {
      const attributes = [
        'id',
        'type',
        'state',
        'agenda',
        'groups',
        'groups.sessions',
        'groups.checkoutItems',
        'groups.checkoutItems.checkout',
        // 'groups.weekly',
      ];
      fetchAuthPublication(id, attributes);
      fetchAuthHasLastTermAccepted();
    }
  }, [id]);

  if (statusFetch === 'loading') {
    return <LinearProgress />;
  }

  const publicationWithNoStartedGroups = {
    ...publication,
    groups: publication?.groups?.filter((group) => !group.started),
  };

  return (
    <>
      {(!id || errorFetch) && (
        <SimpleError>{errorFetch?.message}</SimpleError>
      )}
      {!!publication && (
        <>
          {publication?.agenda === 'scheduled' && publication?.type === 'class' && (
            <StepHorariosClass 
              publication={publicationWithNoStartedGroups}
              hasLastTermAccepted={hasLastTermAccepted}
              saveAuthUserTermAccepted={saveAuthUserTermAccepted}
            />
          )}
          {publication?.agenda === 'scheduled' && publication?.type === 'course' && (
            <StepHorariosCourse 
              publication={publicationWithNoStartedGroups} 
              hasLastTermAccepted={hasLastTermAccepted}
              saveAuthUserTermAccepted={saveAuthUserTermAccepted}
            />
          )}
          {publication?.agenda === 'flexible' && (
            <StepHorariosFlexible 
              publication={publicationWithNoStartedGroups}
              hasLastTermAccepted={hasLastTermAccepted}
              saveAuthUserTermAccepted={saveAuthUserTermAccepted}
            />
          )}
        </>
      )}
    </>
  );
};

export default StepHorarios;
