import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { breakpoints, primaryDark, textOnWhite2 } from '../../../themes/gurapp.theme';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin: 1rem 4rem;
  padding: 2.5rem 2rem 1rem 2rem;
  border-radius: .5rem;

  @media (min-width:${breakpoints.values.md}px) {
    margin: .7rem;
    padding: 1rem;
    transition: .3s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const ContainerImage = styled.div`
  @media (min-width:${breakpoints.values.md}px) {
    height: 50px;
    display: flex;
    align-items: center;
  }
`;

export const ImageBenefitCard = styled.img`
  max-width: 60px;

  @media (min-width:${breakpoints.values.md}px) {
    max-width: 35px;
  }
`;

export const TitleCard = styled(Typography)`
  text-align: center;
  color: ${primaryDark};
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1.5rem;
  letter-spacing: inherit;
  
  @media (min-width:${breakpoints.values.md}px) {
    margin-top: .5rem;
    font-size: .9rem;
    max-width: 200px;
    line-height: 1.2;
  }
  
`;
TitleCard.defaultProps = {
  variant: 'h6',
};

export const ContentCard = styled(Typography)`
  text-align: center;
  color: ${textOnWhite2};
  margin: 1rem 0;
  font-size: .9rem;
  line-height: 1.2rem;

  @media (min-width:${breakpoints.values.md}px) {
    margin: .6rem 0;
    font-size: .85rem;
  }
`;