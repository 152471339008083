import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Grid, Pagination } from '@mui/material';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import MainContainer from '../../../../components/containers/main-container/MainContainer';
import useAdminReviews from './useAdminReviews';
import { ChangeEvent, useEffect, useState } from 'react';
import ReviewTable from './components/review-table/ReviewTable';
import PageTitle from '../../../../components/typographies/PageTitle';
import PageSubtitle from '../../../../components/typographies/PageSubtitle';
import { SimpleButton } from '../../../../components/buttons';
import useReviewMailing from '../home/useReviewMailing';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';
import Paragraph from '../../../../components/typographies/Paragraph';

const MyAccountAdminReviews = () => {
  const { fetchAdminReviews, data, count, status, page, totalPages } = useAdminReviews();
  const [filter, setFilter] = useState({ page: '', per_page: '' });

  const [searchParams] = useSearchParams();

  const idParam: string | null = searchParams.get('id');
  const searchParam: string | null = searchParams.get('search');
  const pageParam: string | null = searchParams.get('page');

  useEffect(() => {
    setFilter({
      ...(idParam && { id: idParam }),
      ...(searchParam && { search: searchParam }),
      per_page: '10',
      page: `${(parseInt(pageParam || '1', 10))}`,
    });
  }, [searchParams]);

  useEffect(() => {
    if (filter.page) {
      fetchAdminReviews(`?${filter && createSearchParams(filter)}`);
    }
  }, [filter]);

  const handleClickPage = (event: ChangeEvent<unknown>, pageEvent: number) => {
    fetchAdminReviews(`?${filter && createSearchParams({
      ...filter,
      page: `${pageEvent}`,
    })}`);
  };

  const { sendMailing, status: statusSendMailing, data: dataSendMailing } = useReviewMailing();
  const { traceBtnClick } = useAnalytics();
  
  return (
    <MainContainer>
      <NavigationBar variant="variant4" />
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12}>
          <PageTitle>Administración / Listado de Reviews</PageTitle>
          {statusSendMailing === 'loaded' ? (<Paragraph>{dataSendMailing}</Paragraph>) : 
            <SimpleButton
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a gatillar mails desde menú de admin',
                  product: 'Gatillar mails',
                });
                sendMailing();
              }} 
              loading={statusSendMailing === 'loading'} 
              disabled={statusSendMailing === 'loading'}
            >
            Gatillar mails
            </SimpleButton>
          }
        </Grid>
        <Grid item xs={12}>
          {status === 'loaded' && (
            <PageSubtitle>
              {`${count || '0'} resultados`}
            </PageSubtitle>
          )}
        </Grid>
        <Grid item xs={12}>
          <ReviewTable data={data} status={status} />
        </Grid>
        {page && (
          <Grid item xs={12} display="flex" justifyContent="center" marginTop={1} marginBottom={4}>
            <Pagination
              count={totalPages}
              page={page}
              color="primary"
              onChange={handleClickPage}
            />
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
};

export default MyAccountAdminReviews;
