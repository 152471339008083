import { Rating, Grid } from '@mui/material';
import { ReviewEntity } from '../../../entities';
import Paragraph from '../../typographies/Paragraph';
import { useState } from 'react';
import { LinkButton } from '../../buttons';

const Review = ({ evaluation, comment, evaluatedAt }: Partial<ReviewEntity>) => {
  const limit = 200;
  // Estado para manejar si el texto está expandido o no
  const [isExpanded, setIsExpanded] = useState(false);

  // Función para truncar el texto
  const truncateText = (text: string) => {
    if (text.length <= limit) return text; // Si el texto es más corto que el límite, no truncar
    
    const truncated = text.substring(0, limit); // Recorta hasta el límite
    const lastSpaceIndex = truncated.lastIndexOf(' '); // Encuentra el último espacio
    return lastSpaceIndex !== -1 ? truncated.substring(0, lastSpaceIndex) + '...' : truncated; // Trunca hasta el último espacio
  };

  // Texto truncado
  const truncatedComment = truncateText(comment || '');
  
  return (
    <Grid container direction={'column'}>
      <Grid item display={'flex'}>
        <Rating
          value={evaluation}
          precision={0.5}
          readOnly
          size="small"
        />
        <Paragraph fontSize={14} style={{ opacity: 0.6, marginLeft: 4 }}>
          {evaluatedAt?.toString()}
        </Paragraph>
      </Grid>
      <Grid item>
        <Paragraph>{isExpanded ? comment : truncatedComment}</Paragraph>
        {truncatedComment !== comment && (
          <LinkButton underline='always' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Ver menos' : 'Ver más'}</LinkButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Review;