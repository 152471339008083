import API from './config/apiInstance';
import apiError from './apiError';

export async function getAdminCheckouts(params: string) {
  try {
    const response = await API.get(`/admin/checkout${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminCheckouts',
      message: 'Hubo un problema al obtener compras',
    });
  }
}

export async function postResendEmail(params: string) {
  try {
    const response = await API.post(`/admin/checkout/resendEmail${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/resendEmail',
      message: 'Hubo un problema al reenviar email',
    });
  }
}