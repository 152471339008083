import React, {
  forwardRef, useContext, useEffect, useState,
} from 'react';
import { Grid, Typography } from '@mui/material';
import { GroupEntity } from '../../../entities';
import {
  StyledWatchOutlinedIcon,
  StyledTodayOutlinedIcon,
  StyledButton,
} from './Group.styled';
import CartControl from '../cart-control/CartControl';
import { useTranslation } from 'react-i18next';
import CartContext from '../../../contexts/CartContext';
import SimpleButton from '../../buttons/simple-button/SimpleButton';
import useCart from '../../../hooks/useCart';
import { GroupDetail } from '../../publication/group-detail/GroupDetail';
import { Label } from '../../typographies';
import SimpleDialog from '../../dialogs/SimpleDialog/SimpleDialog';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';

interface GroupProps {
  group?: GroupEntity | any;
  indexGroup?: number;
  showCartButton?: boolean;
  disabledActions?: boolean;
}

export const getGroupTitle = (group: GroupEntity): string => {
  let title = '';

  // Manejo de agenda flexible
  if (group?.agenda === 'flexible') {
    const sessionCount = group?.sessions?.length || 0;
    const sessionText = sessionCount === 1 ? 'sesión' : 'sesiones';
    const daysText = group?.weekAsArray?.length > 0 ? ' los días:' : '';

    title += `Horario flexible\ncon ${sessionCount} ${sessionText} a elegir${daysText}`;
  }

  // Manejo de agenda scheduled
  if (group?.agenda === 'scheduled') {
    const sessionCount = group?.sessions?.length || 0;
    title += sessionCount > 1 ? '\n- Inicio ' : '';
    if (group?.firstSession) {
      title += `${group.firstSession.dateFormat}, ${group.firstSession.startTime} hrs.`;
    }
  }

  return title;
};

const Group = forwardRef<HTMLDivElement, GroupProps>(
  ({
    group, indexGroup, showCartButton = false, disabledActions = false, ...props
  }, ref) => {
    const [openModalSessions, setOpenModalSessions] = useState<boolean>(false);
    const [itemInCart, setItemInCart] = useState<any>(null);
    const { t } = useTranslation('components', { keyPrefix: 'group' });
    const { traceBtnClick } = useAnalytics();
    const { cart = [], setOpenCart } = useContext(CartContext);
    const [hasFirstItemInCart, setHasFirstItemInCart] = useState<boolean>(cart?.length > 0);
    const { addQuantityGroup, removeQuantityGroup, status } = useCart();

    const onClickRemoveOne = () => {
      traceBtnClick({
        step: 'step desminuir 1 elemento del carrito en grupo',
        product: '- (menos)',
      });
      removeQuantityGroup?.(group.id);
    };

    const onClickAddOne = () => {
      traceBtnClick({
        step: 'step aumentar 1 elemento del carrito en grupo',
        product: '+ (más)',
      });
      addQuantityGroup?.(group.id);
    };

    const onClickAddFirstOne = () => {
      traceBtnClick({
        step: 'step agregar 1 elemento al carrito en grupo',
        product: 'Agregar',
      });
      addQuantityGroup?.(group.id);
    };

    useEffect(() => {
      setItemInCart(cart.find((item) => item.group.id === group?.id));
    }, [cart]);

    useEffect(() => {
      if (status !== 'loading' && cart?.length === 1 && !hasFirstItemInCart) {
        setHasFirstItemInCart(true);
        setOpenCart(true);
      }
    }, [status]);

    const title = getGroupTitle(group);
    
    return (
      <>
        <div ref={ref} {...props}>
          <Typography fontWeight={500} mb={1}>
            {title}
          </Typography>
          {group?.agenda === 'flexible' && (
            <>
              {group?.weekAsArray?.map((day: any) => (
                <Typography key={day.value} mt={1}>
                  {day.value}<br/>
                  {day.times?.map((time: any) => (
                    <React.Fragment key={`${day}${time}`}>de {time?.startTime} a {time?.endTime}</React.Fragment>
                  ))}
                </Typography>
              ))}
            </>
          )}
          {group?.agenda === 'scheduled' && !!group?.sessions?.length && (
            <>
              {(group.sessions.length === 1) && (
                <Grid container pt={.5} pb={.5} alignItems={'center'}>
                  <Grid item xs={2}>
                    <StyledWatchOutlinedIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Label variant='variant4'>
                      {`Hora: ${group?.sessions?.[0].startTime} a ${group?.sessions?.[0].endTime}`}
                    </Label>
                    <Label variant='variant4'>
                      {`Duración: ${group?.sessions?.[0].durationFormat}`}
                    </Label>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledTodayOutlinedIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Label variant='variant4'>
                      {`${group?.sessions?.length} ${group?.sessions?.length > 1 ? 'clases' : 'clase'}`}
                    </Label>
                  </Grid>
                </Grid>
              )}
              {(group.sessions.length > 1) && (
                <Grid container pt={.5}>
                  <Grid item xs={12} textAlign="center" paddingTop={0}>
                    <StyledButton 
                      variant="text" 
                      onClick={() => { 
                        traceBtnClick({
                          step: 'step ver horarios en grupo',
                          product: t('schedules'),
                        });
                        setOpenModalSessions(true); 
                      }}>
                      {t('schedules')}
                    </StyledButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {showCartButton && (
            <Grid item xs={12} textAlign="center" pt={1}>
              {(itemInCart?.quantity > 0) ? (
                <CartControl
                  onClickAddOne={onClickAddOne}
                  onClickRemoveOne={onClickRemoveOne}
                  quantity={itemInCart?.quantity}
                  disabled={status === 'loading' || disabledActions}
                  loading={status === 'loading'}
                  limit={group?.quotaAvailable}
                />
              ) : (
                <SimpleButton 
                  onClick={onClickAddFirstOne} 
                  variant="contained"
                  disabled={status === 'loading' || disabledActions}  
                  loading={status === 'loading'}
                >
                  Agregar al carrito
                </SimpleButton>
              )}
            </Grid>
          )}
        </div>
        <SimpleDialog 
          open={openModalSessions}
          onClose={() => setOpenModalSessions(false)}
          title={group?.publication?.title}
          scroll="paper"
          actions={
            itemInCart?.quantity > 0 ? (
              <CartControl 
                disabled={status === 'loading' || disabledActions}
                onClickAddOne={onClickAddOne} 
                onClickRemoveOne={onClickRemoveOne} 
                quantity={itemInCart?.quantity}
                limit={group?.quotaAvailable}
              />
            ) : (
              <SimpleButton 
                disabled={status === 'loading' || disabledActions}
                onClick={onClickAddOne} 
                variant="contained" 
                loading={status === 'loading'}
              >
                Agregar al carrito
              </SimpleButton>
            )}
        >
          <>
            <Label variant="variant1" style={{ marginBottom: 8, fontWeight: 400 }}>{t(`modal.title.${group?.publication?.agenda}`)}</Label>
            <GroupDetail group={group} />
          </>
        </SimpleDialog>
      </>
    );
  },
);

Group.displayName = 'Group';
export default Group;
