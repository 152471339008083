import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { NavigationBottom } from '../../../../../components/navigations';
import useDetails from '../../useDetails';
import { GroupEntity, PublicationEntity } from '../../../../../entities';
import SimpleDialog from '../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useDialog from '../../../../../hooks/useDialog';
import { getMapGroup, initialGroup, isIdTemporal } from '../StepHorarios.utils';
import PeriodicityNo from './PeriodicityNo';
import { stepHorariosSchema } from '../StepHorarios.schema';
import { LinkButton } from '../../../../../components/buttons';
import PageSubtitle from '../../../../../components/typographies/PageSubtitle';
import getPublicationId from '../../../../../utils/getPublicationId';
import TermDialog from '../components/term-dialog/TermDialog';
import { useState } from 'react';

interface StepHorariosClassProps {
  publication: PublicationEntity;
  hasLastTermAccepted?: boolean;
  saveAuthUserTermAccepted: () => void;
}
const StepHorariosClass = ({ publication, hasLastTermAccepted, saveAuthUserTermAccepted }: StepHorariosClassProps) => {
  const { identifier } = useParams();
  const id = getPublicationId(identifier as string);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-horarios.class' });
  const navigate = useNavigate();
  
  const { updateAuthGroups, toReviewAuthPublication, status: statusUpdate, error: errorUpdate } = useDetails();
  // const { dialog, onCloseDialog } = useDialog(errorUpdate || error, 'Error');
  const { dialog, onCloseDialog } = useDialog(errorUpdate, 'Error');

  const initialGroups: any = publication?.groups && publication?.groups?.length > 0 ? 
    publication?.groups : [initialGroup];

  const publicationOriginalGroups = publication?.groups?.filter((group: GroupEntity) => !isIdTemporal(group?.id)) || [];
  
  const [openTerms, setOpenTerms] = useState(false);
  const [formValues, setFormValues] = useState({});

  const process = async (values: any) => {
    saveAuthUserTermAccepted();
    let groups: GroupEntity[] = [];
    groups = getMapGroup(values.groups.map((group: any) => ({
      id: group.id || 'temporal',
      quota: group.quota,
      agenda: group.agenda || publication.agenda,
      sessions: group.sessions,
    })));

    const success = await updateAuthGroups(publication.id || 0, groups);
    if (!success) {
      return;
    }
    const toReview = publication?.state === 'draft';
    if (toReview) {
      const successToReview = await toReviewAuthPublication(publication.id || 0);
      if (!successToReview) {
        return;
      }
    }
    if (publicationOriginalGroups.length > 0) {
      window.location.reload();
    } else {
      navigate(`/my-publication/${identifier}/details/final-step?type=${publication.type}&toReview=${toReview}&toSave=true`);
    }
  };

  const form = useFormik({
    enableReinitialize: false,
    initialValues: {
      groups: initialGroups,
      // periodicity: publication?.weekly ? 'weekly' : 'no',
      // weeklySince: publication?.weekly?.since ? dayjs(publication?.weekly?.since) : null,
      // weeklyUntil: publication?.weekly?.until ? dayjs(publication?.weekly?.until) : null,
      // isEditMode: initialIsEditMode,
      // week: initialWeek,
      // removeDates: getInitialRemoveDates(publication?.weekly?.week),
    },
    validationSchema: stepHorariosSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      if (hasLastTermAccepted) {
        process(values);
      } else {
        setFormValues(values);
        setOpenTerms(true);
      }     
    },
    // if (values.periodicity === 'weekly') {
    //   const weekAvailable = values.week?.filter((item: DayOfWeek) => 
    //     item.times && item.times?.length > 0 && item.times?.every((time: any) => time.startTime && time.endTime && time.quota));
    //   const dates = getDatesBetween({ 
    //     since: values.weeklySince as Dayjs, 
    //     until: values.weeklyUntil as Dayjs, 
    //     weekAvailable,
    //     removes: values.removeDates,
    //   });
    //   if (dates?.length === 0) {
    //     setError({ message: 'No se encontraron fechas disponibles' });
    //     return;
    //   }
    //   groups = getMapGroup(dates.map((date: any) => ({
    //     id: 'temporal',
    //     quota: date.quota,
    //     periodicity: values.periodicity,
    //     weekly: { 
    //       since: values.weeklySince,
    //       until: values.weeklyUntil,
    //       week: getPeriodicityWeek(weekAvailable, values.removeDates),
    //     },
    //     sessions: [{
    //       id: 'temporal',
    //       date: date.fecha,
    //       startTime: date.startTime,
    //       endTime: date.endTime,
    //     }],
    //   })));
    //   groups = [...publicationOriginalGroups.map((group: GroupEntity) => ({ id: group.id, sessions: [] })), ...groups];
    // }
  });
  const handleClose = () => {
    setOpenTerms(false);
  };
  const handleContinue = () => {
    process(formValues);
    handleClose();
  };

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <TermDialog 
        open={openTerms} 
        handleClose={handleClose}
        handleAccept={handleContinue}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageSubtitle>
            {publicationOriginalGroups.length > 0 ? t('subtitleExists') : t('subtitle')}
          </PageSubtitle>
        </Grid>
        {publicationOriginalGroups.length > 0 && publication?.state === 'approved' && 
        <Grid item xs={12}>
          <LinkButton
            target='_blank'
            href={`/my-publication/${publication.id}/explorers`}
            rel="noopener"
            texts={['', 'Haz click aquí para ir a ver tus sesiones y exploradores']}
          />
        </Grid>}
      </Grid>

      {/* <Grid container direction="column" paddingTop={1} spacing={0.5} paddingBottom={1}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={6}>
            <FormControl margin="dense" fullWidth>
              <Select
                size='small'
                name="periodicity"
                label={'Recurrencia'}
                placeholder='Selecciona'
                variant="outlined"
                value={form.values.periodicity}
                onChange={(event: any) => {
                  if (event.target.value === 'no') {
                    form.setFieldValue('groups', initialGroups);
                    form.setFieldValue('week', []);
                  }
                  if (event.target.value === 'weekly') {
                    form.setFieldValue('groups', []);
                    form.setFieldValue('week', initialWeek);
                  }
                  form.setFieldValue('periodicity', event.target.value);
                }}
                error={form.touched.periodicity && Boolean(form.errors.periodicity)}
                helperText={form.touched.periodicity && form.errors.periodicity as string}
                disabled={publicationOriginalGroups.length > 0 || statusUpdate === 'loading'}
                fullWidth
              >
                {periodicities?.map((periodicity) => (
                  <MenuItem value={periodicity.value} key={periodicity.value}>
                    <Typography style={{ fontSize: '0.875rem' }}>{periodicity.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={6}>
            <FormControl margin="dense" fullWidth>
               <FormHelperText variant="outlined" error={localForm.touched.quota && Boolean(localForm.errors.quota)}>
                  {!!group?.quotaSold && group?.quotaSold > 1 && t('warning-delete-plural', { number: group?.quotaSold }) as string}
                  {!!group?.quotaSold && group?.quotaSold === 1 && t('warning-delete-singular', { number: group?.quotaSold }) as string}
                  {localForm.touched.quota && localForm.errors.quota as string}
                </FormHelperText>
            </FormControl>
          </Grid>
          {form.values.periodicity === 'weekly' && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl margin="dense" fullWidth>
                  <DateInput
                    disablePast
                    shouldDisableDate={disableToday}
                    label={'Desde (fecha de inicio)'}
                    value={form.values.weeklySince}
                    onChange={(value) => {
                      form.setFieldValue('weeklySince', value);
                    }}
                    disabled={!form.values.isEditMode || statusUpdate === 'loading'}
                    size='small'
                    fullWidth
                    name="weeklySince"
                    error={form.touched.weeklySince && Boolean(form.errors.weeklySince)}
                    helperText={form.touched.weeklySince && form.errors.weeklySince as string}
                    renderInput={() => <></>} 
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="dense" fullWidth>
                  <DateInput
                    disablePast
                    shouldDisableDate={disableToday}
                    label={'Hasta (fecha de término)'}
                    value={form.values.weeklyUntil}
                    onChange={(value) => {
                      form.setFieldValue('weeklyUntil', value);
                    }}
                    disabled={!form.values.isEditMode || statusUpdate === 'loading'}
                    size='small'
                    fullWidth
                    name="weeklyUntil"
                    error={form.touched.weeklyUntil && Boolean(form.errors.weeklyUntil)}
                    helperText={form.touched.weeklyUntil && form.errors.weeklyUntil as string}
                    renderInput={() => <></>} 
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Grid> */}

      <PeriodicityNo 
        statusUpdate={statusUpdate}
        form={form}
      />

      {/* {form.values.periodicity === 'weekly' && (
        <PeriodicityWeekly
          statusUpdate={statusUpdate}
          form={form}
        />
      )} */}
      
      <NavigationBottom
        handleClickSave={() => form.handleSubmit()}
        handleClickPrevious={()=> {
          const previousPageIdentifier = `/my-publication/${identifier}/details/step-agenda`;
          const previousPageId = `/my-publication/${id}/details/step-agenda`;
          const from = sessionStorage.getItem('from');
          return (from === previousPageIdentifier || from === previousPageId) ? navigate(-1) : navigate(previousPageIdentifier);
        }}
        disabled={statusUpdate === 'loading'}
        saveProps={{ loading: statusUpdate === 'loading' }}
      />
    </>
  );
};

export default StepHorariosClass;
