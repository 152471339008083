import styled from '@emotion/styled';
import { Skeleton, Typography } from '@mui/material';

export const StyledChipContainer = styled.div`
  text-align: right;
`;

export const CardNoActionArea = styled.div`
  filter: grayscale(100%);
`;

export const SkeletonPublicationCard = styled(Skeleton)`
  border-radius: 15px;
  margin: 0 auto;
`;

export const Title = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`;
SkeletonPublicationCard.defaultProps = { variant: 'rectangular', width: 290, height: 315 };
