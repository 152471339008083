import API from './config/apiInstance';
import apiError from './apiError';

export async function getTerm() {
  try {
    const response = await API.get('/term');
    return response.data;
  } catch (error) {
    throw apiError({ code: '/term', message: 'Hubo un problema al intentar buscar términos y condiciones' });
  }
}
