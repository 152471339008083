import { createTheme } from '@mui/material/styles';

export const primaryMain = '#6501A3';
export const primaryLight = '#9840d5';
export const primaryDark = '#222144';
export const secondaryMain = '#00929A';
export const secondaryLight = '#00B2B8';
export const secondaryDark = '#00606B';
export const textOnWhite2 = '#757575';
export const white = '#fff';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export const borderRadius = 15;
const MuiTypographyGeneral = {
  // marginBottom: '8px',
  // lineHeight: 1.3,
  letterSpacing: 0.25,
  opacity: 0.90,
  whiteSpace: 'pre-line',
};
export default createTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: white,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px',
          fontWeight: 500,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            ...MuiTypographyGeneral,
            fontWeight: 500,
            fontSize: '18px',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            ...MuiTypographyGeneral,
            fontWeight: 300,
            fontSize: '16px',
            // marginBottom: '16px',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '16px',
            ...MuiTypographyGeneral,
          },
        },
        // {
        //   props: { variant: 'h3' },
        //   style: {
        //     fontWeight: 500,
        //     fontSize: '16px',
        //     ...MuiTypographyGeneral,
        //   },
        // },
        // {
        //   props: { variant: 'h4' },
        //   style: {
        //     fontWeight: 400,
        //     fontSize: '16px',
        //     ...MuiTypographyGeneral,
        //   },
        // },
      ],
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-complete': {
            color: '#04919A',
          },
          '&.Mui-active': {
            color: '#04919A',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 300,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius,
          '&.Mui-selected': {
            backgroundColor: primaryMain,
            color: white,
            '& .MuiListItemIcon-root': {
              color: white,
            },
            '&:hover': {
              backgroundColor: primaryLight,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius,
          paddingLeft: '24px',
          paddingRight: '24px',
          inlineSize: 'max-content',
        },
        outlined: {
          backgroundColor: 'white',
        },
      },
    },
  },
});
