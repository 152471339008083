import { forwardRef } from 'react';
import {
  Slide,
  Grid,
  CircularProgress,
} from '@mui/material';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import { TransitionProps } from '@mui/material/transitions';
import { SimpleButton } from '../../../../../../components/buttons';
import useTerm from '../../../../../../hooks/useTerm';
import Markdown from 'react-markdown';

interface Props {
  open?: boolean;
  handleClose?: () => void;
  handleAccept?: () => void;
}

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const TermDialog = forwardRef<HTMLDivElement, Props>(
  ({ open, handleClose, handleAccept }, ref) => {
    const { term, status } = useTerm();
    const marginModal = 48;
    const paddingModal = 32;
    const logo = 64;
    const margin = marginModal * 2 + paddingModal * 2 + logo;
    const minHeight = `calc(100vh - ${margin}px)`;

    const CloseButton = <SimpleButton variant="outlined" onClick={handleClose}>Cerrar</SimpleButton>;
    const AcceptButton = <SimpleButton variant="contained" onClick={handleAccept}>Aceptar</SimpleButton>;
    return (
      <SimpleDialog 
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="lg"
        transitionDuration={500}
        actions={<>
          {!!handleClose ? CloseButton : undefined}
          {!!handleAccept ? AcceptButton : undefined}
        </>}
        fullWidth
        ref={ref}
      >
        <Grid container justifyContent="center">
          <Grid item minHeight={minHeight}>
            {status === 'loading' && <CircularProgress size={32} />}
            {<Markdown children={term?.content} />}
          </Grid>
        </Grid>
      </SimpleDialog>);
  });

export default TermDialog;