import Markdown from 'react-markdown';
import { CircularProgress, Grid } from '@mui/material';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import useTerm from '../../hooks/useTerm';

const TermsAndConditionsPage = () => {
  const { term, status } = useTerm();
  return (
    <MainContainer>
      <NavigationBar />
      <Grid container justifyContent="center">
        <Grid item>
          {status === 'loading' && <CircularProgress size={32} />}
          {<Markdown children={term?.content} />}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default TermsAndConditionsPage;
