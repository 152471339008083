import { Grid } from '@mui/material';
import NavigationBar from '../../../../components/navigations/navigation-bar/NavigationBar';
import { ListButton } from '../../../../components/buttons';
import { useNavigate } from 'react-router-dom';
import DividedContainer from '../../../../components/containers/divided-container/DividedContainer';
import PageTitle from '../../../../components/typographies/PageTitle';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';

const MyAccountAdminHome = () => {
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();
  
  return (
    <>
      <DividedContainer>
        <NavigationBar variant="variant4" />
        
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle>Administración</PageTitle>
          </Grid>
          <Grid item xs={12}>
            <ListButton 
              title="Listado de Publicaciones" 
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a listado de publicaciones desde menú de admin',
                  product: 'Listado de Publicaciones',
                });
                navigate('/my-account/admin/publications');
              }} 
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton 
              title="Listado de Usuarios" 
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a listado de usuarios desde menú de admin',
                  product: 'Listado de Usuarios',
                });
                navigate('/my-account/admin/users');
              }} 
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton 
              title="Listado de Compras" 
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a listado de compras desde menú de admin',
                  product: 'Listado de Compras',
                });
                navigate('/my-account/admin/checkouts');
              }} 
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton 
              title="Orden de Publicaciones" 
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a orden de publicaciones desde menú de admin',
                  product: 'Orden de Publicaciones',
                });
                navigate('/my-account/admin/publications-order');
              }} 
            />
          </Grid>
          <Grid item xs={12}>
            <ListButton 
              title="Listado de Reviews" 
              onClick={()=> { 
                traceBtnClick({
                  step: 'step ir a listado de reviews',
                  product: 'Listado de Reviews',
                });
                navigate('/my-account/admin/reviews');
              }} 
            />
          </Grid>
        </Grid>
      </DividedContainer>
    </>
  );
};

export default MyAccountAdminHome;
