import styled from '@emotion/styled';
import { Divider, CardMedia } from '@mui/material';
import SimpleButton from '../../buttons/simple-button/SimpleButton';
import { borderRadius } from '../../../themes/gurapp.theme';

export const StyledDivider = styled(Divider)`
  margin: 20px 0px;
  width: 100%;
`;

// export const StyledButton = styled(SimpleButton)`
//   font-size: 16px;
//   padding: 0px;
//   margin:0px;
//   font-weight: normal;
//   color: #04919A;
//   text-decoration: underline;
//   text-transform: initial;

//   &:hover {
//     background-color: #fff;
//     text-decoration: underline;
//   }
// `;

export const StyledButtonDelete = styled(SimpleButton)`
  font-size: 16px;
  padding: 0px;
  margin:0px;
  font-weight: normal;
  color: #6501A3;
  text-decoration: underline;
  text-transform: initial;

  &:hover {
    background-color: #fff;
    text-decoration: underline;
  }
`;

// export const StyledTypography = styled(Typography)`
//   display: inline;
//   margin-right: 8px;
// `;

export const StyledCardMedia = styled(CardMedia)`
  height: 0;
  padding-top: 112%;
  margin-bottom: 8px;
  border-radius: ${borderRadius}px
`;
