import { forwardRef, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  InputBase,
  Tabs,
  Tab,
} from '@mui/material';
// import { DateInput, SliderHours } from '../../../../components/inputs';
import { Search as SearchIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import { ContainerSearch, StyledSkeleton } from './Search.styled';
import { useTranslation } from 'react-i18next';
import useSearch from './useSearch';
import { CategoryEntity } from '../../../../entities';
import IconButton from '../../../../components/buttons/icon-button/IconButton.component';
// import MoreFilters from './MoreFilters';
// import { dayjs } from '../../../../configs/dayjs';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';
import { TodoIcon } from './icons/TodoIcon';
import { DeportesIcon } from './icons/DeportesIcon';
import { BienestarIcon } from './icons/BienestarIcon';
import { MusicaIcon } from './icons/MusicaIcon';
import { CocinaIcon } from './icons/CocinaIcon';
import { OtraIcon } from './icons/OtraIcon';
import { ArteIcon } from './icons/ArteIcon';

interface InitialValues {
  categoryId?: number;
  search?: string;
  date?: string;
  time?: string;
  location?: string;
  mode?: string;
}
interface ISearch {
  disabled?: boolean;
  initialValues?: InitialValues;
  onSubmit: (values: any) => void;
}

export const Search = forwardRef<HTMLDivElement, ISearch>(
  ({
    onSubmit,
    initialValues = {},
    disabled,
    ...props
  }, ref) => {
    const { t } = useTranslation(['publications']);
    const { fetchAll, categories, status } = useSearch();
    const { traceBtnClick } = useAnalytics();
    const [category, setCategory] = useState<CategoryEntity | null>(null);
    
    const form = useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

    const handleEnterPress = (event: any) => {
      if (event.key === 'Enter') {
        form.handleSubmit();
      }
    };

    useEffect(() => {
      fetchAll();
    }, []);

    useEffect(() => {
      if (categories?.length > 0) {
        const selected = form.values.categoryId ? categories.find((c) => c.id === form.values.categoryId) : null;
        setCategory(selected || null);
      }
    }, [categories, form.values.categoryId]);

    // const onChangeSliderHours = (values?: string[]) => {
    //   form.setFieldValue('time', values?.join(',')) ;
    // };

    // const onChangeMoreFilters = (params: { [name: string]: string | undefined }) => {
    //   form.setFieldValue(Object.keys(params)[0], Object.values(params)[0]);
    // };
    const icons: Record<number, JSX.Element> = {
      0: <TodoIcon />,
      1: <DeportesIcon />,
      2: <BienestarIcon />,
      3: <ArteIcon />,
      4: <MusicaIcon />,
      5: <CocinaIcon />,
      6: <OtraIcon />,
    };

    return (
      <ContainerSearch ref={ref} {...props}>
        {status === 'loading' ? (
          <StyledSkeleton width="100%" />
        ) : (
          <>
            <Grid container rowSpacing={1}>
              <Grid item xs={0} md={3} />
              <Grid item xs={12} md={6}>
                <Paper
                  component="form"
                  variant="outlined"
                  sx={{ display: 'flex', alignItems: 'center', borderRadius: 15 }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    name="search"
                    placeholder={t('form.search.placeholder') as string}
                    value={form.values.search}
                    onChange={form.handleChange}
                    error={(form.touched.search && Boolean(form.errors.search))}
                    fullWidth
                    disabled={disabled}
                    onKeyDown={handleEnterPress}
                    inputProps={{ maxLength: 100 }}
                  />
                  <IconButton 
                    type="button" 
                    aria-label="search"
                    onClick={() => { 
                      traceBtnClick({
                        step: 'step buscar',
                        product: 'icon buscar',
                      });
                      form.handleSubmit();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item xs={0} md={3} />
            </Grid>
            <Grid container rowSpacing={1} pt={2} pb={1} justifyContent={'center'}>
              <Tabs
                value={category}
                onChange={(_, value: any) => {
                  form.setFieldValue('categoryId', value?.id);
                  form.submitForm();
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs category"
              >
                {categories.map((item: CategoryEntity) => (
                  <Tab 
                    icon={icons[item.id] || <TodoIcon />} 
                    label={item.name} 
                    value={item} 
                    sx={{ 
                      textTransform: 'none', 
                      color: '#00000099',
                      fontWeight: 400,
                      '&.Mui-selected': {
                        color: '#00000099',
                      } }}/>
                ))}
              </Tabs>
            </Grid>
          </>
        )}

      </ContainerSearch>
    );
  },
);

//          {/* {!hideMoreFilters && (
//               <>
//                 <Grid item xs={12} md={4}>
//                   <DateInput
//                     disablePast
//                     label={t('form.calendar.label') as string}
//                     value={form.values.date || null} 
//                     onChange={(value: any) => form.setFieldValue('date', !!value ? (dayjs(value).format('YYYY-MM-DD').toString()) : '')}
//                     disabled={disabled}
//                     name="date"
//                     size="medium"
//                     fullWidth 
//                     renderInput={() => <></>} 
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={4}>
//                   <SliderHours
//                     name="time"
//                     marks={['00:00', '8:00', '12:00', '18:00', '23:59']}
//                     values={form.values.time?.split(',') || []}
//                     onChange={onChangeSliderHours}
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={4}>
//                   <MoreFilters 
//                     onChange={onChangeMoreFilters}
//                     values={{ 
//                       mode: form.values.mode || undefined, 
//                       location: form.values.location || undefined, 
//                     }}
//                     locationsValues={locations}
//                   />
//                 </Grid> 
//               </>)} */}