import { Typography } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { GroupEntity, PublicationEntity } from '../../../../entities';
import { SimpleButton } from '../../../../components/buttons';
import { useTranslation } from 'react-i18next';
import { forwardRef, useState } from 'react';
import { getAuthExplorersByGroup } from '../../../../services/authGroup';
import { Container, Row, StyledButton } from './Group.styled';
import { GroupDetail } from '../../../../components/publication/group-detail/GroupDetail';
import SimpleDialog from '../../../../components/dialogs/SimpleDialog/SimpleDialog';
import useAnalytics from '../../../../hooks/useAnalytics/useAnalytics';
import { getGroupTitle } from '../../../../components/cart/group/Group';

interface GroupProps {
  group: GroupEntity;
  publication?: PublicationEntity;
}

const Group = forwardRef<HTMLDivElement, GroupProps>(
  ({ group, publication, ...props }, ref) => {
    const { id, quota, quotaSold } = group;
    const { t } = useTranslation('my-publication', { keyPrefix: 'explorers' });
    const [loading, setLoading] = useState<boolean>(false);
    const [openModalSessions, setOpenModalSessions] = useState<boolean>(false);
    const { traceBtnClick } = useAnalytics();
    
    const onClickDownloadExplorers = async () => {
      traceBtnClick({
        step: 'step descargar exploradores',
        product: t('downloadButton'),
      });
      if (id) {
        try {
          setLoading(true);
          const response = await getAuthExplorersByGroup(+id);
          const href = URL.createObjectURL(response?.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${id}_explorers.csv`);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    const title = `${t(`unit.${publication?.type}`, '') || t('unit.class')} \n${getGroupTitle(group)}`;

    return (
      <Container {...props} ref={ref} >
        <Row>
          <Typography mr={1}>
            {title}
          </Typography>
          <Typography mr={1} alignSelf={'flex-end'} fontWeight="bold">
            {`${t('sells')} ${quotaSold || 0} ${t('to')} ${quota}`}
          </Typography>
        </Row>
        <Row>
          {publication?.type === 'course' && (
            <StyledButton 
              variant="text" 
              onClick={() => { 
                traceBtnClick({
                  step: 'step ver horarios en grupo',
                  product: t('schedules'),
                });
                setOpenModalSessions(true); 
              }}>
              {t('schedules')}
            </StyledButton>
          )}
          {quotaSold && quotaSold > 0 ? (
            <SimpleButton 
              onClick={() => onClickDownloadExplorers()} 
              startIcon={<DownloadOutlinedIcon />}
              loading={loading}
              disabled={loading}
            >
              {t('downloadButton')}
            </SimpleButton>
          ) : (
            <SimpleButton disabled>
              {t('downloadButtonNoContent')}
            </SimpleButton>
          )}
        </Row>

        <SimpleDialog 
          open={openModalSessions}
          onClose={() => setOpenModalSessions(false)}
          title={t('modal.title')}
          scroll="paper"
        >
          <GroupDetail group={group} />
        </SimpleDialog>
      </Container>);
  });

export default Group;
