import { forwardRef, ReactElement } from 'react';
import {
  DialogContent, DialogContentText, DialogActions, IconButton, Dialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTitle, StyledDialogTitle } from './SimpleDialog.styled';

interface SimpleDialogProps {
  onClose?: () => void;
  open?: boolean;
  title?: string;
  content?: string;
  actions?: ReactElement;
  children?: ReactElement;
  TransitionComponent?: any;
  transitionDuration?: number;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?:  'xs' | 'sm' | 'md' | 'lg' | 'xl';
  scroll?: 'body' | 'paper';
}

const DialogHeader = (props: SimpleDialogProps) => {
  const { children, onClose, ...other } = props;

  return (
    <StyledDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

const SimpleDialog = forwardRef<HTMLDivElement, SimpleDialogProps>(
  ({
    onClose, open = false, title, content, actions, children, ...rest
  }, ref) => {
    const handleClose = () => {
      onClose?.();
    };
    return (
      <Dialog onClose={handleClose} open={open} ref={ref} {...rest}>
        <DialogHeader onClose={handleClose}>
          <StyledTitle variant='variant2'>{title}</StyledTitle>
        </DialogHeader>
        {(content || children) && (
          <DialogContent>
            {content && <DialogContentText style={{ whiteSpace: 'pre-line' }}>{content}</DialogContentText>}
            {children}
          </DialogContent>
        )}
        <DialogActions style={{ marginBottom: 8 }}>
          {actions}
        </DialogActions>
      </Dialog>
    );
  },
);

SimpleDialog.displayName = 'SimpleDialog';
export default SimpleDialog;
