import { useState } from 'react';
import { postAddReview } from '../../services/review';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
}

const useReview = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const sendAddReview = async (props: { id: string, evaluation: number, comment?: string }) => {
    setState({ status: 'loading' });
    try {
      await postAddReview(props);
      setState({ status: 'loaded' });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    sendAddReview,
  };
};

export default useReview;