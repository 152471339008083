import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Container, Rating, Typography } from '@mui/material';
import { Title } from '../../components/typographies';
import GurappLogo from '../../components/logos/gurapp-logo/GurappLogo';
import { SimpleButton } from '../../components/buttons';
import { useFormik } from 'formik';
import useReview from './useReview';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';
import TextFieldForm from '../../components/inputs/text-field-form-v2/TextFieldForm';
import PageTitle from '../../components/typographies/PageTitle';

const AddReview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const evaluation = useRef(parseInt(searchParams.get('evaluation') as string));
  const id = useRef(searchParams.get('id') as string);
  const publicationTitle = useRef(searchParams.get('publicationTitle') as string);
  const { traceBtnClick } = useAnalytics();
  
  const { status, error, sendAddReview } = useReview();
  const form = useFormik({
    initialValues: {
      id: id.current,
      evaluation: evaluation.current,
      comment: undefined,
    },
    onSubmit: async (values) => {
      await sendAddReview(values);
    },
  });

  useEffect(() => {
    searchParams.delete('id');
    searchParams.delete('evaluation');
    searchParams.delete('publicationTitle');
    setSearchParams(searchParams);
  }, [id, evaluation, publicationTitle]);

  return (
    <Container>
      <GurappLogo />
      {form.errors.comment && <Typography variant='body2' color='error'>{form.errors.comment}</Typography>}

      <Grid container spacing={1}>
        {
          status === 'loaded' && !error ? (
            <Grid item xs={12}>
              <Title variant="variant3">
              Gracias por tu evaluación
              </Title>    
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Title variant="variant3">
                Valora tu experiencia
                </Title>
              </Grid>
              <Grid item xs={12}>
                <PageTitle>
                  {`Publicación "${publicationTitle.current}"`}
                </PageTitle>
              </Grid>
              <Grid item xs={12}>
                <Rating
                  name="evaluation"
                  value={form.values.evaluation}
                  onChange={form.handleChange}
                  size="large"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldForm
                  name="comment"
                  form={form}
                  label={'Cuéntanos más detalles de cómo fue tu experiencia ¡Les servirá a otros exploradores como tú!'}
                  inputProps={{ maxLength: 500 }}
                  multiline
                  minRows={2}
                  maxRows={6}
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleButton
                  onClick={() => { 
                    traceBtnClick({
                      step: 'step enviar evaluación',
                      product: 'Enviar',
                    });
                    form.handleSubmit();
                  }}
                >
                Enviar
                </SimpleButton>
              </Grid>
            </>
          )
        }
      </Grid>
        
    </Container>
  );
};

export default AddReview;