import { Grid, Typography } from '@mui/material';
import { forwardRef } from 'react';
import {
  PersonOutlined as ModeIcon,
  WatchOutlined as ScheduleIcon,
  SupervisorAccountOutlined as QuorumIcon,
  FaceOutlined as AgeRangeIcon,
} from '@mui/icons-material';
import styled from '@emotion/styled';

const iconProps = { fontSize: 'large' as any, style: { opacity: 0.54 } };
const icons = {
  mode: <ModeIcon {...iconProps} />,
  schedule: <ScheduleIcon {...iconProps} />,
  quorum: <QuorumIcon {...iconProps} />,
  agerange: <AgeRangeIcon {...iconProps} />,
};

interface IconInfoPros {
  icon?: 'mode' | 'schedule' | 'quorum' | 'agerange' | undefined;
  text?: string;
}

const Container = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
`;
const IconInfo = forwardRef<HTMLDivElement, IconInfoPros>(
  ({ icon = undefined, text, ...rest }, ref) => (
    <Container {...rest} ref={ref}>
      {icon && icons[icon]}
      <Typography style={{ opacity: .6 }}>{text}</Typography>
    </Container>
  ),
);

export default IconInfo;