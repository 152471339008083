import { forwardRef, Fragment, useState } from 'react';
import {  Paper, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Box, Typography, CircularProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CheckoutEntity } from '../../../../../../entities';
import { dayjs } from '../../../../../../configs/dayjs';
import getPriceFormatted from '../../../../../../utils/getPriceFormatted';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import useAdminCheckouts from '../../useAdminCheckouts';

interface CheckoutTableProps {
  data?: CheckoutEntity[];
  status?: 'idle' | 'loading' | 'loaded' | 'error';
}

const styleOneLine = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Row = (props: { row: CheckoutEntity }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const getStateLabel = () => {
    if (row.state === 'paid') {
      return 'Pagado 🟢';
    }
    if (row.state === 'intent') {
      return 'Intención de compra 🟡';
    }
    if (row.state === 'rejected') {
      return 'Rechazado 🔴';
    }
    return `${row.state}`;
  };
  const stateLabel = getStateLabel();
  const { resendEmailPayer, status: statusPayer, error: errorPayer } = useAdminCheckouts();
  const { resendEmailGuru, status: statusGuru, error: errorGuru } = useAdminCheckouts();

  const rowStyle = {
    flexDirection: 'row' as any,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          {row.state === 'paid' && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>)}
        </TableCell>
        <TableCell component="th" scope="row">{row.id}</TableCell>
        <TableCell component="th" scope="row" style={styleOneLine}>{stateLabel}</TableCell>
        <TableCell component="th" scope="row">{row.checkoutItems?.length}</TableCell>
        <TableCell component="th" scope="row">{row.payer?.email}</TableCell>
        <TableCell >{dayjs(row.createdAt).format('DD/MM/YYYY')}</TableCell>
      </TableRow>
      {row.state === 'paid' && (
        <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <Typography gutterBottom component="div" sx={{ margin: 2 }}>
                Pagos
                </Typography>
                <Table size="small" aria-label="payments" style={{ border: '2px solid rgba(0, 0, 0, 0.1)' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nro de operación</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell align="right">Monto de la transacción</TableCell>
                      <TableCell align="right">Monto total pagado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.merchantOrders?.[0]?.bodyJson?.payments?.map((payment: any) => (
                      <TableRow key={payment?.id}>
                        <TableCell component="th" scope="row">
                          {payment?.id}
                        </TableCell>
                        <TableCell>{payment?.status}</TableCell>
                        <TableCell align="right">{getPriceFormatted(payment?.transaction_amount)}</TableCell>
                        <TableCell align="right">{getPriceFormatted(payment?.total_paid_amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Typography gutterBottom component="div" sx={{ margin: 2 }}>
                Pagador
                </Typography>
                <Table size="small" aria-label="payer" style={{ border: '2px solid rgba(0, 0, 0, 0.1)' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre y apellido</TableCell>
                      <TableCell>Teléfono</TableCell>
                      <TableCell>Email compra</TableCell>
                      <TableCell>Email usuario</TableCell>
                      <TableCell align='center'>Reenvío email a pagador</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {`${row?.payer?.name} ${row?.payer?.lastname}`}
                      </TableCell>
                      <TableCell>{`${row?.payer?.phoneAreaCode || ''} ${row?.payer?.phoneNumber}`}</TableCell>
                      <TableCell>{row?.payer?.email}</TableCell>
                      <TableCell>{row.payer?.user?.email || 'Sin usuario logueado'}</TableCell>
                      <TableCell align='center' scope="row" style={rowStyle}>
                        {statusPayer === 'loading' && <CircularProgress size={20} />}
                        {statusPayer !== 'loading' && <IconButton onClick={() => resendEmailPayer(row.id)}>
                          <EmailOutlinedIcon />
                        </IconButton>}
                        {statusPayer === 'loaded' && <Typography>Enviado</Typography>}
                        {statusPayer === 'error' && <Typography color='error'>{errorPayer}</Typography>}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography gutterBottom component="div" sx={{ margin: 2 }}>
                Items
                </Typography>
                <Table size="small" aria-label="checkoutItems" style={{ border: '2px solid rgba(0, 0, 0, 0.1)' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Título</TableCell>
                      <TableCell>Grupo id</TableCell>
                      <TableCell>Publicación creada por</TableCell>
                      <TableCell align='center'>Reenvío email a gurú</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.checkoutItems?.map((checkoutItem) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {checkoutItem?.group?.publicationId}
                        </TableCell>
                        <TableCell>
                          {checkoutItem?.group?.publication?.title}
                        </TableCell>
                        <TableCell>{checkoutItem?.group?.id}</TableCell>
                        <TableCell>{checkoutItem?.group?.publication?.createdBy?.displayName} - {checkoutItem?.group?.publication?.createdBy?.email}</TableCell>
                        <TableCell align='center' scope="row" style={rowStyle}>
                          {statusGuru === 'loading' && <CircularProgress size={20} />}
                          {statusGuru !== 'loading' && <IconButton onClick={() => resendEmailGuru(row.id)}>
                            <EmailOutlinedIcon />
                          </IconButton>}
                          {statusGuru === 'loaded' && <Typography>Enviado</Typography>}
                          {statusGuru === 'error' && <Typography color='error'>{errorGuru}</Typography>}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const CheckoutTable = forwardRef<HTMLDivElement, CheckoutTableProps>(
  ({ data, status, ...rest }, ref) => {
    return (
      <>
        <TableContainer component={Paper} ref={ref} {...rest}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Cantidad de items</TableCell>
                <TableCell>Pagador</TableCell>
                <TableCell>Fecha de creación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loaded' && (
                data?.map((row: CheckoutEntity) => (
                  <Row key={row.id} row={row} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {status === 'loading' && <LinearProgress />}
      </>
    );
  },
);

CheckoutTable.displayName = 'CheckoutTable';
export default CheckoutTable;
