import { forwardRef, Fragment } from 'react';
import {  Paper, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Rating } from '@mui/material';
import { ReviewEntity } from '../../../../../../entities';
import { dayjs } from '../../../../../../configs/dayjs';

interface ReviewTableProps {
  data?: ReviewEntity[];
  status?: 'idle' | 'loading' | 'loaded';
}

const Row = (props: { row: ReviewEntity }) => {
  const { row } = props;
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row"></TableCell>
        <TableCell component="th" scope="row">{row.publication?.title}</TableCell>
        <TableCell component="th" scope="row">{row.payer?.email}</TableCell>
        <TableCell >{!!row.sendEmailAfterThat && dayjs(row.sendEmailAfterThat).format('DD/MM/YYYY')}</TableCell>
        <TableCell >{!!row.sentEmailAt ? dayjs(row.sentEmailAt).format('DD/MM/YYYY') : 'No enviado aún'}</TableCell>
        <TableCell component="th" scope="row">
          {!!row.evaluation && <Rating
            value={row.evaluation}
            precision={0.5}
            readOnly
            size="small"
          />}
          {!!row.evaluatedAt && dayjs(row.evaluatedAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell component="th" scope="row">{row.comment}</TableCell>
      </TableRow>
    </Fragment>
  );
};

const ReviewTable = forwardRef<HTMLDivElement, ReviewTableProps>(
  ({ data, status, ...rest }, ref) => {
    return (
      <>
        <TableContainer component={Paper} ref={ref} {...rest}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Publicación</TableCell>
                <TableCell>Email pagador</TableCell>
                <TableCell>Enviar email después del:</TableCell>
                <TableCell>Email enviado el:</TableCell>
                <TableCell>Evaluación</TableCell>
                <TableCell>Comentario</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loaded' && (
                data?.map((row: ReviewEntity) => (
                  <Row key={row.id} row={row} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {status === 'loading' && <LinearProgress />}
      </>
    );
  },
);

ReviewTable.displayName = 'ReviewTable';
export default ReviewTable;
