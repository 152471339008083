import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleButton from '../simple-button/SimpleButton';
import GoogleIcon from '@mui/icons-material/Google';

interface IButton {
  onClick?: () => any;
  disabled?: boolean;
  children?: string;
}
const Button = forwardRef<HTMLButtonElement, IButton>(
  ({
    onClick, disabled = false, children, ...rest
  }, ref) => {
    const { t } = useTranslation(['components']);
    return (
      <SimpleButton 
        onClick={onClick} 
        startIcon={<GoogleIcon />}
        disabled={disabled}
        style={{ width: '100%', backgroundColor: 'rgb(67, 133, 245)' }}
        variant='contained'
        size='large'
        ref={ref}
        {...rest}
      >
        {children || t('google-button.title') as string}
      </SimpleButton>

    );
  },
);

Button.displayName = 'Button';
export default Button;
