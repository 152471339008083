import API from './config/apiInstance';
import apiError from './apiError';

interface PutAuthPublicationProps {
  id: number;
  newState: 'approved' | 'rejected';
  payload?: any;
}

export async function getAdminPublications(params: string) {
  try {
    const response = await API.get(`/admin/publication${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminPublications',
      message: 'Hubo un problema al obtener publicaciones',
    });
  }
}

export async function getAdminPublication(id: number, attributes?: string[]) {
  try {
    const response = await API.get(`/admin/publication/${id}?attributes=${attributes?.join(',') ?? ''}`);
    return response?.data || undefined;
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminPublication',
      message: 'Hubo un problema al obtener publicación',
    });
  }
}

export async function putAdminStatePublication({ id, newState }: PutAuthPublicationProps) {
  try {
    const response = await API.put(`/admin/publication/${id}/new-state/${newState}`);
    return response;
  } catch (error) {
    throw apiError({
      code: '/admin/publication/putAdminStatePublication',
      message: 'Hubo un problema al modificar estado de publicación',
    });
  }
}

export async function getAdminNextGroupUpdated() {
  try {
    const response = await API.get('/admin/publication/next-group-updated');
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminNextGroupUpdated',
      message: 'Hubo un problema al obtener orden de publicaciones',
    });
  }
}

export async function putAdminNextGroupUpdated() {
  try {
    const response = await API.put('/admin/publication/next-group-updated');
    return response;
  } catch (error) {
    throw apiError({
      code: '/admin/publication/putAdminNextGroupUpdated',
      message: 'Hubo un problema al intentar modificar orden de publicaciones',
    });
  }
}

export async function sendReviewMails() {
  try {
    const response = await API.post('/admin/publication/send-review-emails');
    return response;
  } catch (error) {
    throw apiError({
      code: '/admin/publication/send-review-emails',
      message: 'Hubo un problema al enviar los mails de reviews',
    });
  }
}
