import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { primaryMain, primaryDark, textOnWhite2 } from '../../../themes/gurapp.theme';
import { breakpoints } from '../../../themes/gurapp.theme';

export const CardContainer = styled.div`
  background-color: #fff;
  border-radius: .3rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem;
  max-width: 255px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width:${breakpoints.values.md}px) {
    max-width: none;
    position: static;
    transform: none;
    margin: 0;
    width: auto;
    max-width: ${breakpoints.values.md - 80}px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width:${breakpoints.values.md}px) {
    flex-direction: row;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`;

export const Title = styled(Typography)`
  color: ${primaryMain};
  font-weight: normal;
  margin-bottom: 1rem;
  
  @media (min-width:${breakpoints.values.md}px) {
    font-size: 1.8rem;
  }
`;
Title.defaultProps = {
  variant: 'h6',
};

export const CircularNumber = styled.div`
  width: 56px;
  height: 56px;
  background-color: #cbc9e7;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

export const Subtitle = styled(Typography)`
  color: ${primaryDark};
  text-align: center;
  white-space: pre-line;
`;
Subtitle.defaultProps = {
  variant: 'subtitle1',
};

export const Content = styled(Typography)`
  text-align: center;
  color: ${textOnWhite2};
`;