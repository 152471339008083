import { useEffect, useState } from 'react';
import { 
  getTerm as getTermService,
} from '../services/term';
import TermEntity from '../entities/TermEntity';

interface StateProps {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  term?: TermEntity;
}

const useTerm = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null });

  const fetchTerm = async () => {
    setState({ status: 'loading' });
    try {
      const term = await getTermService();
      setState({ status: 'loaded', term  });
    } catch (error: any) {
      setState({
        status: 'loaded',
        error,
      });
    }
  };

  useEffect(() => {
    fetchTerm();
  }, []);

  return {
    ...state,
    fetchTerm,
  };
};

export default useTerm;
