import API from './config/apiInstance';
import apiError from './apiError';
import { UserEntity } from '../entities';

export async function getAuthUser() {
  try {
    const response = await API.get('/auth/user');
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    throw apiError({
      code: '/auth/user/getAuthUser',
      message: 'Hubo un problema al obtener datos de usuario',
    });
  }
}

export async function putAuthUser(user: UserEntity) {
  try {
    await API.put('/auth/user', user);
  } catch (error) {
    throw apiError({
      code: '/auth/user/putAuthUser',
      message: 'Hubo un problema al intentar modificar datos de usuario',
    });
  }
}

export async function getAuthPaymentAccount() {
  try {
    const response = await API.get('/auth/payment-account');
    if (response && response.data) {
      if (response.data === 'OK')
        return undefined;
      return response.data;
    }
    return undefined;
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return undefined;
    }
    throw apiError({
      code: '/auth/payment-account/getAuthPaymentAccount',
      message: 'Hubo un problema al obtener cuenta de pago',
    });
  }
}

export async function getAuthHasLastTermAccepted() {
  try {
    const response = await API.get('/auth/user-term-accepted/has-last-term-accepted');
    return response.data;
  } catch (error: any) {
    throw apiError({
      code: '/auth/user-term-accepted/has-last-term-accepted',
      message: 'Hubo un problema al obtener si tiene los últimos términos aceptados',
    });
  }
}

export async function postAuthUserTermAccepted() {
  try {
    const response = await API.post('/auth/user-term-accepted');
    return response.data;
  } catch (error: any) {
    throw apiError({
      code: '/auth/user-term-accepted',
      message: 'Hubo un problema al guardar términos y condiciones',
    });
  }
}

export async function putAuthPaymentAccount(payload: any) {
  try {
    await API.put('/auth/payment-account', payload);
  } catch (error) {
    throw apiError({
      code: '/auth/publication/putAuthPaymentAccount',
      message: 'Hubo un problema al modificar cuenta de pago',
    });
  }
}
