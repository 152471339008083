import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMyPublication from '../useMyPublication';
import { PublicationTemplate } from '../../../components/publication/publication-template/PublicationTemplate';
import { LinearProgress } from '@mui/material';
import getPublicationId from '../../../utils/getPublicationId';
import Groups from '../../../components/publication/groups/Groups';
import SimpleError from '../../../components/errors/simple-error/SimpleError';
import TopMessage from '../../../components/message/top-message/TopMessage';

const Preview = () => {
  const { identifier } = useParams();
  const { fetchAuthPublication, data, status, error: errorFetch } = useMyPublication();

  useEffect(() => {
    const id = getPublicationId(identifier as string);
    const attributes = [
      'id',
      'type',
      'title',
      'description',
      'detail',
      'material',
      'price',
      'mode',
      'modeDetail',
      'location',
      'knowledges',
      'knowledgeDetail',
      'categoryId',
      'category',
      'audiovisuals',
      'state',
      'active',
      'createdById',
      'createdBy',
      'ageranges',
      'groups',
      'groups.sessions',
      'groups.checkoutItems',
      'groups.checkoutItems.checkout',
      // 'groups.weekly',
      'lastGroupDate', 
      'lastGroupStartTime', 
      'agenda',
      'minDuration',
      'minDurationFormat',
      'maxDuration',
      'maxDurationFormat',
      'minQuota',
      'maxQuota',
      'quota',
      'currentQuota',
    ];
    fetchAuthPublication(id, attributes);
  }, []);

  const groups = data?.groups?.filter((group) => !group.started);
  return (
    <>
      {(errorFetch) && (
        <SimpleError>{errorFetch?.message}</SimpleError>
      )}
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && data && (
        <>
          <TopMessage 
            title={'Esta es una previsualización.'}
          />
          <PublicationTemplate publication={data} disabledActions>
            {!!groups && groups?.length > 0  && (
              <Groups groups={data?.groups?.filter((group) => !group.started)} disabledActions/>
            )}
          </PublicationTemplate>
        </>
      )}
    </>
  );
};

export default Preview;