import { useState } from 'react';
import { CheckoutEntity, ListEntity } from '../../../../entities';
import { getAdminCheckouts, postResendEmail } from '../../../../services/adminCheckouts';

interface StateProps extends ListEntity {
  status: 'idle' | 'loading' | 'loaded' | 'error';
  error?: any;
  data?: CheckoutEntity[];
}

const useAdminCheckouts = () => {
  const [state, setState] = useState<StateProps>({ 
    status: 'idle',
  });

  const resendEmail = async (params: any) => {
    setState({ status: 'loading', data: [] });
    try {
      await postResendEmail(params);
      setState({ 
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'error', error });
    }
  };

  const resendEmailPayer = async (checkoutId?: number) => {
    resendEmail(`?checkoutId=${checkoutId}&resendTo=payer`);
  };

  const resendEmailGuru = async (checkoutId?: number) => {
    resendEmail(`?checkoutId=${checkoutId}&resendTo=guru`);
  };

  const fetchAdminCheckouts = async (params: any) => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getAdminCheckouts(params);
      setState({ 
        data: response?.data?.map((checkout: CheckoutEntity) => ({
          ...checkout,
          merchantOrders: checkout.merchantOrders?.map((merchantOrder) => ({
            ...merchantOrder,
            bodyJson: merchantOrder.body ? JSON.parse(merchantOrder.body) : {},
          })),
        })),
        count: response?.count,
        page: response?.page,
        perPage: response?.per_page,
        totalPages: response?.total_pages,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'error', error });
    }
  };

  return {
    ...state,
    fetchAdminCheckouts,
    resendEmailPayer,
    resendEmailGuru,
  };
};

export default useAdminCheckouts;