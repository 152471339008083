import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

const getImagesCompressed = async (imageFiles: any) => {
  const maxWidthOrHeight = 900;
  const userAgent = navigator.userAgent.toLowerCase();
  const isIphone = /iphone|ipad|ipod/.test(userAgent);
  const maxSizeMB = isIphone ? 0.256 : 0.128;
  const initialQuality = isIphone ? 1 : 0.9; // Mayor calidad en iPhone

  const options = {
    maxSizeMB,
    maxWidthOrHeight,
    useWebWorker: true,
    fileType: 'image/jpeg',
    initialQuality,
  };

  const getSizeInMB = (size: number) => size / 1024 / 1024;

  const fileToArray = [];

  for (const file of imageFiles) {
    let processedFile = file;

    // Si el archivo es HEIC, convertirlo a JPEG
    if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });

        // Normalizar a un solo Blob
        const normalizedBlob = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;

        // Crear un nuevo File a partir del Blob convertido
        processedFile = new File([normalizedBlob], file.name.replace(/\.heic$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        continue; // Saltar el archivo si hay un error
      }
    }

    // Si es iPhone, evitar conversión a WebP y mantener el formato original
    let compressedFile = await imageCompression(processedFile, options);

    // Si sigue siendo demasiado grande, ajustar calidad y dimensiones
    if ((getSizeInMB(compressedFile.size)) > maxSizeMB) {
      compressedFile = await imageCompression(processedFile, { 
        ...options, 
        maxWidthOrHeight: maxWidthOrHeight / 1.2, 
        initialQuality: initialQuality - 0.1,
      });
    }

    const blobToFile = new File(
      [compressedFile],
      compressedFile.name,
      { type: compressedFile.type },
    );
   
    fileToArray.push(blobToFile);
  }

  return fileToArray;
};

export default getImagesCompressed;