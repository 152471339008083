// import API from './config/apiInstance';
import apiError from './apiError';

export async function getCategories(): Promise<any> {
  try {
    const count = 6;
    const page = 1;
    const perPage = 10;
    const totalPages = Math.ceil(count / perPage);
    const rows = [
      {
        'id': 4,
        'name': 'Música',
        'description': 'Guitarra, Canto, Teclado, Producción, DJ y otros.',
      },
      {
        'id': 3,
        'name': 'Arte',
        'description': 'Pintura, Fotografía, Manualidades, Diseño y otros.',
      },
      {
        'id': 1,
        'name': 'Deportes',
        'description': 'Entrenamientos, Baile, Ciclismo, Raquetas y otros.',
      },
      {
        'id': 6,
        'name': 'Otro',
        'description': null,
      },
      {
        'id': 2,
        'name': 'Bienestar',
        'description': 'Meditación, Yoga, Flores de Bach, Astrología y otros.',
      },
      {
        'id': 5,
        'name': 'Cocina',
        'description': 'Pastelería, Panadería, Vegano, Coctelería y otros.',
      },
    ];
    const response =  {
      data: {
        page,
        per_page: perPage <= count ? perPage : count,
        count,
        total_pages: totalPages,
        data: rows,
      },
    };
    // const response = await API.get('/category');
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw apiError({ code: '/category', message: 'Hubo un problema al intentar buscar categorías' });
  }
}
