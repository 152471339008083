import { forwardRef } from 'react';
import Paragraph from '../../typographies/Paragraph';

interface SectionProps {
  title?: string,
  description?: string,
}

const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ title, description }, ref) => (
    <div ref={ref}>
      <Paragraph fontWeight={500}>{title}</Paragraph>
      <Paragraph>{description}</Paragraph>
    </div>
  ),
);

export default Section;