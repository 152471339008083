import styled from '@emotion/styled';
import { Box, Theme } from '@mui/material';

export const FooterBox = styled(Box)(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.primary.contrastText,
  backgroundColor: theme?.palette.primary.dark,
  padding: theme?.spacing(2),
  position: 'relative',
  paddingTop: '32px',
  paddingBottom: '32px',
}));
