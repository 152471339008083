import API from './config/apiInstance';
import apiError from './apiError';

export async function getAdminReviews(params: string) {
  try {
    const response = await API.get(`/admin/review${params}`);
    return response?.data || [];
  } catch (error) {
    throw apiError({
      code: '/admin/publication/getAdminReviews',
      message: 'Hubo un problema al obtener reviews',
    });
  }
}