import { useState } from 'react';
import { ListEntity, ReviewEntity } from '../../../../entities';
import { getAdminReviews } from '../../../../services/adminReviews';

interface StateProps extends ListEntity {
  status: 'idle' | 'loading' | 'loaded';
  error?: any;
  data?: ReviewEntity[];
}

const useAdminUsers = () => {
  const [state, setState] = useState<StateProps>({ 
    status: 'idle',
  });

  const fetchAdminReviews = async (params: any) => {
    setState({ status: 'loading', data: [] });
    try {
      const response = await getAdminReviews(params);
      setState({ 
        data: response?.data,
        count: response?.count,
        page: response?.page,
        perPage: response?.per_page,
        totalPages: response?.total_pages,
        status: 'loaded', 
      });
    } catch (error) {
      setState({ status: 'loaded', error });
    }
  };

  return {
    ...state,
    fetchAdminReviews,
  };
};

export default useAdminUsers;